import styled from 'styled-components'
import { colors } from '../styles'

const Title = styled.h2`
  padding-bottom: 12px;
  padding-top: 6px;
  font-weight: 400;
  font-size: 0.9rem;
  color: ${colors.silverChalice};
`

export default Title
