import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../styles'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import TextWrapper from '../components/TextWrapper'
import Paragraph from '../components/Paragraph'
import Title from '../components/Title'
import { ExternalLink } from '../components/Link'

const StyledTitle = styled.h1`
  padding: 12px 0;
  font-weight: 400;
  font-size: 1rem;
  color: ${colors.mineShaft};
  text-decoration: underline;
`

const WorkPage = () => (
  <Layout>
    <SEO title='Work' />
    <TextWrapper>
      <StyledTitle>Positions held in the European Commission</StyledTitle>
      <Paragraph>
        European Commission official 1979 - 2014. Final grade AD 14.
      </Paragraph>
      <Title>2012 – 2014</Title>
      <Paragraph>
        European Commission / Anti-Fraud Office (OLAF), Head of Unit for Customs
        Anti-Fraud Policy and Strategic Analysis, Reporting, Joint Operations. A
        30-strong Unit preparing and negotiating legislation; analysing and
        reporting fraud on EU financial interests; organising and carrying out
        Joint Customs Operations with Member States and third countries;
        negotiating with third countries on customs anti-fraud action;
        organising customs anti-fraud information systems.
      </Paragraph>
      <Paragraph>
        Relevant article:{' '}
        <ExternalLink
          href='https://kluwerlawonline.com/journalarticle/Global+Trade+and+Customs+Journal/10.11/GTCJ2015047'
          target='_blank'
        >
          "Strengthening the Fight against Customs Fraud in the EU"
        </ExternalLink>
      </Paragraph>
      <Title>2010 – 2012</Title>
      <Paragraph>
        OLAF, Acting Director, Policy. A 110-strong directorate responsible for
        antifraud legislation, fraud prevention, institutional and external
        antifraud relations, analysis/reporting, joint operations and programme
        management (Hercule and Pericles). Tasks involved representation of OLAF
        in high-level fora, policy planning and coordination, as well as
        management of a large team.
      </Paragraph>
      <Title>2004 – 2010</Title>
      <Paragraph>
        OLAF, Head of Unit for the protection of the euro banknotes and coins
        against fraud and counterfeiting: policies and management of anti-fraud
        programmes (Pericles and Hercule).
      </Paragraph>
      <Paragraph>
        Relevant article:{' '}
        <ExternalLink
          href='https://eucrim.eu/media/issue/pdf/eucrim_issue_2008-03.pdf'
          target='_blank'
        >
          "The Protection of the euro against Counterfeiting"
        </ExternalLink>
      </Paragraph>
      <Title>2002 – 2004</Title>
      <Paragraph>
        OLAF, Deputy Head of Unit for antifraud policy and legislation.{' '}
      </Paragraph>
      <Paragraph>
        Relevant article:{' '}
        <ExternalLink
          href='https://dialnet.unirioja.es/servlet/articulo?codigo=678963'
          target='_blank'
        >
          "La protection des billets et pieces en euro contre le faux monnayage
          - Le cadre juridique et technique"
        </ExternalLink>
      </Paragraph>
      <Title>1999 – 2002</Title>
      <Paragraph>
        Economic and Financial Affairs (ECFIN), Deputy, then acting Head of Unit
        for Transition issues related to EMU. Responsibilities: legal,
        institutional and technical analyses of the euro; establishment of the
        technical and legal structure for the protection of euro coins.
      </Paragraph>
      <Title>1989 – 1999</Title>
      <Paragraph>
        ECFIN, Principal Administrator then Deputy Head of Unit for Monetary
        Union: Exchange Rates and Domestic Monetary Policies. Responsibilities:
        budgetary, monetary, financial and institutional/legal analyses for the
        implementation of the Economic and Monetary Union; evaluation and
        reporting on the convergence criteria for joining the euro area.
      </Paragraph>
      <Title>1980 – 1989</Title>
      <Paragraph>
        ECFIN, Country Desk Greece: analysis of Greek Economy; economic
        forecasts, budgetary assessment. Analysis of the stabilization
        programmes of Greece; Community loan to Greece and follow up.
      </Paragraph>
      <Title>1979 – 1980</Title>
      <Paragraph>
        Commission's linguist service. Responsibility: adapting Community
        legislation on prospect of accession of Greece.
      </Paragraph>
      <Title>Before 1979</Title>
      <Paragraph>
        Greece: 1976: Commercial Secretary (Diplomatic Service of Ministry of
        (the then) External Trade in Greece). 1973 - 1976: Various positions in
        private sector (mainly advertising and teaching). 1971 - 1973: Army
        service.
      </Paragraph>
    </TextWrapper>
  </Layout>
)

export default WorkPage
